<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-form class="p-5" @submit.prevent="submitForm">
    <b-row>
      <b-col cols="12" lg="6"
        ><label> 19 - <b>Critical Q:</b> What was the last day you worked in the UK? </label></b-col
      >
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            type="date"
            v-model="ukwork.dateOfLeavingLastUKEmployment"
            @input="setTouched('dateOfLeavingLastUKEmployment')"
            :class="v$.ukwork.dateOfLeavingLastUKEmployment.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div
            v-for="error of v$.ukwork.dateOfLeavingLastUKEmployment.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>20 - How many years in total did you work in the UK?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            type="number"
            min="0"
            max="80"
            step="0.1"
            v-model="ukwork.yearsWorkInUK"
            @input="setTouched('yearsWorkInUK')"
            :class="v$.ukwork.yearsWorkInUK.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-input>
          <div v-for="error of v$.ukwork.yearsWorkInUK.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"><label>21 - Just before leaving UK were you?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="ukwork.workInUKBeforeLeaving"
            :options="workInUKBeforeLeavingOptions"
            @input="setTouched('workInUKBeforeLeaving')"
            @change="workInUKBeforeLeavingChange()"
            :class="v$.ukwork.workInUKBeforeLeaving.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
          ></b-form-select>
          <div v-for="error of v$.ukwork.workInUKBeforeLeaving.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="ukwork.workInUKBeforeLeaving == 'Employed'">
      <b-col cols="12" lg="6"><label>22 - What was the name of your last UK Employer?</label></b-col>
      <b-col cols="6">
        <div class="input-container">
          <b-form-input
            v-model="ukwork.nameOfLastUKEmployer"
            @input="setTouched('nameOfLastUKEmployer')"
            :class="v$.ukwork.nameOfLastUKEmployer.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="Enter"
          ></b-form-input>
          <div v-for="error of v$.ukwork.nameOfLastUKEmployer.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
          <b-form-text>
            It is CRITICAL to list this, even if the Employer/Company is now gone out of business.
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="ukwork.workInUKBeforeLeaving == 'Employed'">
      <b-col cols="12" lg="6"><label>23 - What was your last UK employer's address?</label></b-col>
      <b-col cols="12" lg="6">
        <b-row>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Country:</label>
              <b-form-select
                v-model="ukwork.lastUKEmployerAddress.country"
                @input="setTouched('lastUKEmployerAddress.country')"
                :class="v$.ukwork.lastUKEmployerAddress.country.$error ? 'is-invalid' : ''"
                :options="getCountryOptions()"
                @change="countryChange(ukwork.lastUKEmployerAddress)"
                :disabled="true"
              ></b-form-select>
              <div
                v-for="error of v$.ukwork.lastUKEmployerAddress.country.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container" v-if="getCountryStateOptions(ukwork.lastUKEmployerAddress.country).length">
              <label>County/State:</label>
              <b-form-select
                v-model="ukwork.lastUKEmployerAddress.state"
                @input="setTouched('lastUKEmployerAddress.state')"
                :class="v$.ukwork.lastUKEmployerAddress.state.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :options="getCountryStateOptions(ukwork.lastUKEmployerAddress.country)"
                placeholder="Enter County/State"
              ></b-form-select>
              <div
                v-for="error of v$.ukwork.lastUKEmployerAddress.state.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="12">
            <div class="input-container">
              <label>Number & Street:</label>
              <b-form-input
                v-model="ukwork.lastUKEmployerAddress.street"
                @input="setTouched('lastUKEmployerAddress.street')"
                :class="v$.ukwork.lastUKEmployerAddress.street.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :formatter="capitalizeWords"
                placeholder="Enter Number & Street"
              ></b-form-input>
              <div
                v-for="error of v$.ukwork.lastUKEmployerAddress.street.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Town/District:</label>
              <b-form-input
                v-model="ukwork.lastUKEmployerAddress.city"
                @input="setTouched('lastUKEmployerAddress.city')"
                :class="v$.ukwork.lastUKEmployerAddress.city.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                :formatter="capitalizeWords"
                placeholder="Enter Town/District"
              ></b-form-input>
              <div
                v-for="error of v$.ukwork.lastUKEmployerAddress.city.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="input-container">
              <label>Postcode:</label>
              <b-form-input
                v-model="ukwork.lastUKEmployerAddress.postcode"
                @input="setTouched('ukwork.lastUKEmployerAddress.postcode')"
                :class="v$.ukwork.lastUKEmployerAddress.postcode.$error ? 'is-invalid' : ''"
                :disabled="isLoading"
                placeholder="Enter Postcode"
              ></b-form-input>
              <div
                v-for="error of v$.ukwork.lastUKEmployerAddress.postcode.$errors"
                class="invalid-feedback"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
              <b-form-text>
                Important: Due to how HMRC systems work for the case officer who will review your application, it is
                CRITICAL that you find the accurate Postcode for your Last UK Employer (even if they are no longer in
                business) - Use
                <a href="https://postcodefinder.net/" target="_blank">https://postcodefinder.net/</a>
                to find this.
              </b-form-text>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="ukwork.workInUKBeforeLeaving">
      <b-col cols="12" lg="6"><label>24 - Start Date </label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            type="date"
            v-model="ukwork.startDateOfLastUKEmployer"
            @input="setTouched('startDateOfLastUKEmployer')"
            :class="v$.ukwork.startDateOfLastUKEmployer.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div v-for="error of v$.ukwork.startDateOfLastUKEmployer.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="ukwork.workInUKBeforeLeaving">
      <b-col cols="12" lg="6"><label>25 - End Date </label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            type="date"
            v-model="ukwork.endDateOfLastUKEmployer"
            @input="setTouched('endDateOfLastUKEmployer')"
            :class="v$.ukwork.endDateOfLastUKEmployer.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div v-for="error of v$.ukwork.endDateOfLastUKEmployer.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, numeric, requiredIf, helpers, between } from '@vuelidate/validators';
export default {
  name: 'UkWorkStep',
  inject: ['occupationOptions', 'countryListOptions'],
  props: {
    ukwork: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      v$: useVuelidate(),
      todayDate: new Date().toISOString().slice(0, 10),
      workInUKBeforeLeavingOptions: [
        { value: null, text: 'Select' },
        { value: 'Employed', text: 'Employed' },
        { value: 'Self-Employed', text: 'Self-Employed' },
        { value: 'Unemployed', text: 'Unemployed' }
      ],
      singleOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ]
    };
  },
  validations() {
    return {
      ukwork: {
        dateOfLeavingLastUKEmployment: {
          required: helpers.withMessage('Answer required', required),
          minValue: helpers.withMessage('Invalid Date.', (value) => {
            return new Date(value) > new Date('1900-01-01');
          }),
          maxValue: helpers.withMessage('Date not must be after today', (value) => {
            return new Date(value) <= new Date();
          })
        },
        yearsWorkInUK: {
          required: helpers.withMessage('Answer required', required),
          numeric,
          between: between(0, 80)
        },
        workInUKBeforeLeaving: {
          required: helpers.withMessage('Answer required', required)
        },
        nameOfLastUKEmployer: {
          requiredIf: helpers.withMessage(
            'Answer required',
            requiredIf(function () {
              return this.ukwork.workInUKBeforeLeaving === 'Employed';
            })
          )
        },
        lastUKEmployerAddress: {
          country: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return this.ukwork.workInUKBeforeLeaving === 'Employed';
              })
            )
          },
          city: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return this.ukwork.workInUKBeforeLeaving === 'Employed';
              })
            )
          },
          state: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return (
                  this.ukwork.workInUKBeforeLeaving === 'Employed' &&
                  this.getCountryStateOptions(this.ukwork.lastUKEmployerAddress.country).length
                );
              })
            )
          },
          street: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return this.ukwork.workInUKBeforeLeaving === 'Employed';
              })
            )
          },
          postcode: {
            requiredIf: helpers.withMessage(
              'Answer required',
              requiredIf(function () {
                return this.ukwork.workInUKBeforeLeaving === 'Employed';
              })
            )
          }
        },
        startDateOfLastUKEmployer: {
          requiredIf: helpers.withMessage(
            'Answer required',
            requiredIf(function () {
              return this.ukwork.workInUKBeforeLeaving.length ? true : false;
            })
          ),
          minValue: helpers.withMessage('Invalid Date.', (value) => {
            return new Date(value) > new Date('1900-01-01');
          }),
          maxValue: helpers.withMessage('Date not must be after today', (value) => {
            return new Date(value) <= new Date();
          })
        },
        endDateOfLastUKEmployer: {
          requiredIf: helpers.withMessage(
            'Answer required',
            requiredIf(function () {
              return this.ukwork.workInUKBeforeLeaving.length ? true : false;
            })
          ),
          minValue: helpers.withMessage('Date must be before today and after Start Date', (value) => {
            return new Date(value) <= new Date() && new Date(value) >= new Date(this.ukwork.startDateOfLastUKEmployer);
          })
        }
      }
    };
  },
  methods: {
    workInUKBeforeLeavingChange() {
      if (this.ukwork.workInUKBeforeLeaving !== 'Employed') {
        // eslint-disable-next-line vue/no-mutating-props
        this.ukwork.nameOfLastUKEmployer = null;
        // eslint-disable-next-line vue/no-mutating-props
        this.ukwork.lastUKEmployerAddress = {
          country: 'GB',
          street: null,
          city: null,
          state: null,
          postcode: null
        };
        // eslint-disable-next-line vue/no-mutating-props
        this.ukwork.startDateOfLastUKEmployer = null;
        // eslint-disable-next-line vue/no-mutating-props
        this.ukwork.endDateOfLastUKEmployer = null;
      }
    },
    countryChange(address) {
      address.state = null;
    },
    getCountryOptions() {
      let result = [
        {
          text: 'Choose',
          value: null
        }
      ];
      for (const country in this.countryListOptions) {
        result.push({
          text: this.countryListOptions[country].name,
          value: country.toUpperCase()
        });
      }
      return result;
    },
    getCountryStateOptions(country) {
      let result = [];
      if (country !== null) {
        country = country.toLowerCase();
        for (const stateKey in this.countryListOptions[country].states) {
          result.push({
            text: this.countryListOptions[country].states[stateKey],
            value: stateKey
          });
        }
        if (result?.length) {
          result.unshift({
            text: 'Choose',
            value: null
          });
        }
      }
      return result;
    },
    capitalizeWords(value) {
      return value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    setTouched(theModel) {
      if (theModel === 'dateOfLeavingLastUKEmployment' || theModel == 'all') {
        this.v$.ukwork.dateOfLeavingLastUKEmployment.$touch();
      }
      if (theModel === 'yearsWorkInUK' || theModel == 'all') {
        this.v$.ukwork.yearsWorkInUK.$touch();
      }
      if (theModel === 'workInUKBeforeLeaving' || theModel == 'all') {
        this.v$.ukwork.workInUKBeforeLeaving.$touch();
      }
      if (theModel === 'nameOfLastUKEmployer' || theModel == 'all') {
        this.v$.ukwork.nameOfLastUKEmployer.$touch();
      }
      if (theModel === 'lastUKEmployerAddress.country' || theModel == 'all') {
        this.v$.ukwork.lastUKEmployerAddress.country.$touch();
      }
      if (theModel === 'lastUKEmployerAddress.city' || theModel == 'all') {
        this.v$.ukwork.lastUKEmployerAddress.city.$touch();
      }
      if (theModel === 'lastUKEmployerAddress.state' || theModel == 'all') {
        this.v$.ukwork.lastUKEmployerAddress.state.$touch();
      }
      if (theModel === 'lastUKEmployerAddress.street' || theModel == 'all') {
        this.v$.ukwork.lastUKEmployerAddress.street.$touch();
      }
      if (theModel === 'lastUKEmployerAddress.postcode' || theModel == 'all') {
        this.v$.ukwork.lastUKEmployerAddress.postcode.$touch();
      }
      if (theModel === 'startDateOfLastUKEmployer' || theModel == 'all') {
        this.v$.ukwork.startDateOfLastUKEmployer.$touch();
      }
      if (theModel === 'endDateOfLastUKEmployer' || theModel == 'all') {
        this.v$.ukwork.endDateOfLastUKEmployer.$touch();
      }
    },
    submitForm() {
      this.setTouched('all');
      if (!this.v$.$invalid) {
        this.onSubmit();
      }
    }
  }
};
</script>
