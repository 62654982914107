import Axios from '@/services/axios-instance/axios.service';
const API_URL = process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api` : 'http://localhost:5000/api';

class FormService {
  async createApp(appData) {
    const { data } = await Axios.post(`${API_URL}/form/create-app`, appData);
    return data;
  }
  async getFormData(id, isFullForm = false) {
    const { data } = await Axios.get(`${API_URL}/form/${id}?isFullForm=${isFullForm}`);
    return data;
  }
  async sendFormData(form, id) {
    const { data } = await Axios.post(`${API_URL}/form/${id}`, form);
    return data;
  }
  async sendFullFormData(form, id) {
    console.log('Form Data', form);
    const { data } = await Axios.post(`${API_URL}/full-form/${id}`, form);
    return data;
  }
}

export default new FormService();
