export default [
  {
    value: null,
    text: 'None'
  },
  {
    value: 'Barry',
    text: 'Barry'
  },
  {
    value: 'John R',
    text: 'John R'
  },
  {
    value: 'Megan',
    text: 'Megan'
  },
  {
    value: 'Mike',
    text: 'Mike'
  },
  {
    value: 'Tomas',
    text: 'Tomas'
  },
  {
    value: 'George',
    text: 'George'
  },
  {
    value: 'Ciaran',
    text: 'Ciaran'
  },
  {
    value: 'Paul',
    text: 'Paul'
  },
  {
    value: 'John H',
    text: 'John H'
  }
];
