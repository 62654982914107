<template>
  <div class="progress-bar-container">
    <div class="progress-title">Progress</div>
    <ul class="progressbar">
      <template v-if="steps.length">
        <template v-for="(step, index) in steps" :key="index">
          <li :class="[step.active ? 'active' : '']">
            <span
              class="step-name"
              @click="handleChangeActiveStep(step)"
              :class="currentStep > index ? 'cursor-pointer' : ''"
            >
              <template v-if="step.active">
                <b>{{ step.stepName }}</b>
                {{ step.stepQuestionText }}
              </template>
              <template v-else>
                {{ step.stepName }}
              </template>
            </span>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>
<script>
import './ProgressBar.scss';
/* eslint-disable */
export default {
  name: "ProgressBar",
  props: {
    steps: {
      type: Array,
      default: [],
      required: true,
    },
    currentStep: {
      type: Number,
      default: 1,
      required: true,
    },
    onChangeStep: { type: Function, required: true },
  },
  methods: {
    handleChangeActiveStep(step) {
      const stepId = step.id ?? 1;
      if (step.id < this.currentStep) {
        this.onChangeStep(stepId);
      }
    },
  },
};
</script>
