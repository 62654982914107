import Axios from '@/services/axios-instance/axios.service';
const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/pay/` : 'http://localhost:5000/pay/';

class StripeService {
  async sendFormData(form) {
    const { data } = await Axios.post(`${API_URL}`, form);
    return data;
  }
}

export default new StripeService();
