import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import tooltip from './directives/Tooltip/index.js';
// Plugins
import BootstrapVue3 from 'bootstrap-vue-3';
import VueMask from '@devindex/vue-mask';
// Style
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import './theme/main.scss';
import './theme/tooltip.scss';
import 'vue3-toastify/dist/index.css';
// Lists
import occupationOptions from '@/lists/occupations.js';
import countryListOptions from '@/lists/countryList.js';
import agentsOptions from '@/lists/agents.js';

const app = createApp(App);
app.directive('tooltip', tooltip);
app
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .use(VueMask)
  .provide('occupationOptions', occupationOptions)
  .provide('countryListOptions', countryListOptions)
  .provide('agentsOptions', agentsOptions)
  .mount('#app');
