<template>
  <b-container v-if="stripeLoaded">
    <div v-if="!isPayCompleted" class="payment-form">
      <b-row>
        <b-col>
          <div class="flex-center">
            <img alt="Logo" src="@/assets/logo.png" />
          </div>
        </b-col>
        <b-col cols="12" lg="12"><label class="card-label">Email:</label></b-col>
        <b-col cols="12" lg="12">
          <b-form-input
            type="email"
            v-model.trim="email"
            @input="setTouched('email')"
            :class="v$.email.$error ? 'is-invalid' : ''"
            disabled
            placeholder="Enter Your Email"
          ></b-form-input>
          <div v-for="error of v$.email.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="12">
          <label class="card-label">Personal Details</label>
          <div id="address-element"></div>
        </b-col>
        <b-col cols="12" lg="12">
          <label class="card-label">Card Number</label>
          <div id="card-element"></div>
        </b-col>
        <b-col cols="12">
          <div class="flex-center mt-3">
            <b-form-checkbox v-model="isAgreeTermsConditions" name="isAgreeTermsConditions" :disabled="isPayLoading">
              I agree to XtraPension's
              <a href="https://www.xtrapension.com/terms" target="_blank">Terms & Conditions</a>
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col cols="12" lg="12">
          <template v-if="isPayLoading">
            <div class="spinner-container p-3">
              <b-spinner></b-spinner>
            </div>
          </template>
          <div class="flex-center">
            <button class="btn-main" :disabled="isPayLoading || !isAgreeTermsConditions" @click="pay">
              Pay € {{ formatAmount(amount) }}
            </button>
          </div>
          <div class="flex-center invalid-feedback" v-for="error of v$.email.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
          <div class="flex-center invalid-feedback">
            {{ stripeValidationError }}
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else class="pay-completed-form">
      <b-row>
        <b-col>
          <div class="flex-center">
            <img alt="Logo" src="@/assets/logo.png" />
          </div>
        </b-col>
        <b-col cols="12" lg="12">
          <div class="flex-center">
            <h1>Thanks for Payment!</h1>
            <p class="mt-3 mb-3">A receipt has been emailed to you. (Check your spam/junk folder)</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
  <b-container v-else>
    <b-row>
      <div class="flex-center">
        <div class="spinner-container p-3">
          <b-spinner></b-spinner>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import StripeService from '@/services/stripe-service/stripe.service';
import PaymentService from '@/services/payment-service/payent.service';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { toast } from 'vue3-toastify';
import '../HomeView/HomeView.scss';
export default {
  data() {
    return {
      v$: useVuelidate(),
      stripeLoaded: false,
      isPayLoading: false,
      isPayCompleted: false,
      payCompletedId: null,
      stripe: null,
      cardElement: null,
      addressElement: null,
      fullName: null,
      firstName: null,
      lastName: null,
      shippingAddress: null,
      phone: null,
      email: '',
      paymentId: this.$route.params.id,
      stripeValidationError: '',
      amount: 10000,
      isAgreeTermsConditions: false,
      productName: '',
      customerId: ''
    };
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Email address required', required),
        email
      }
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    if (this.$route?.params?.id) {
      this.getPaymentInfo(this.$route.params.id);
    }
  },
  methods: {
    formatAmount(amount) {
      return amount / 100;
    },
    async getPaymentInfo(id) {
      this.showStripeLoading();
      try {
        const { status, email, amount, productName, customerId } = await PaymentService.getPaymentInfo({
          id: id
        });
        console.log('Get Payment Infor Response', status, email, amount);
        if (status === 'Success' && email && amount && productName) {
          this.amount = amount;
          this.email = email;
          this.productName = productName;
          this.customerId = customerId;
          // eslint-disable-next-line no-undef
          this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
          await this.hideStripeLoading();
          this.createAndMountFormElements();
        }
      } catch (err) {
        toast.error(err.message ?? 'Oops, Something Went Wrong');
        console.log(err);
        this.$router.push('/');
      }
    },
    showStripeLoading() {
      this.stripeLoaded = false;
    },
    hideStripeLoading() {
      this.stripeLoaded = true;
    },
    setTouched(theModel) {
      if (theModel === 'email' || theModel == 'all') {
        this.v$.email.$touch();
      }
    },
    async createAndMountFormElements() {
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        hidePostalCode: true
      });
      this.cardElement.mount('#card-element');
      const options = {
        mode: 'billing',
        fields: {
          phone: 'always',
          name: 'always'
        },
        display: {
          name: 'split'
        },
        validation: {
          phone: {
            required: 'always'
          }
        }
      };
      this.addressElement = elements.create('address', options);
      this.addressElement.mount('#address-element');

      this.cardElement.on('change', this.setValidationCardError);
      this.addressElement.on('change', this.setValidationError);
    },
    setValidationCardError(event) {
      this.stripeValidationError = event.error ? event.error.message : '';
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : '';
      if (event.complete) {
        this.shippingAddress = event.value.address;
        this.fullName = event.value.name;
        this.firstName = event.value.firstName;
        this.lastName = event.value.lastName;
        this.phone = event.value.phone;
      } else {
        this.shippingAddress = null;
        this.fullName = null;
        this.firstName = null;
        this.lastName = null;
        this.phone = null;
      }
    },
    async pay() {
      try {
        this.setTouched('all');
        if (!this.v$.$invalid) {
          if (this.fullName && this.phone && this.shippingAddress && this.email) {
            await this.showPayLoading();
            const result = await this.stripe.createPaymentMethod({
              type: 'card',
              card: this.cardElement,
              billing_details: {
                name: this.fullName,
                email: this.email,
                phone: this.phone,
                address: this.shippingAddress
              }
            });

            this.stripePaymentMethodHandler(result);
          } else {
            this.stripeValidationError = 'Fill in your personal data!';
          }
        }
      } catch (error) {
        this.hidePayLoading();
        console.error(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async stripePaymentMethodHandler(result) {
      try {
        if (result.error) {
          this.stripeValidationError = result.error.message;
          toast.error(result.error.message ?? 'Oops, Something Went Wrong');
          await this.hidePayLoading();
        } else {
          const res = await StripeService.sendFormData({
            payment_method_id: result.paymentMethod.id,
            amount: this.amount,
            email: this.email,
            productName: this.productName,
            customerId: this.customerId,
            setup_future_usage: true,
            shiping_details: {
              name: this.fullName,
              phone: this.phone,
              address: this.shippingAddress
            },
            isMoto: this.$route?.name == 'phonePay' ? true : false
          });
          const paymentResponse = res;
          this.handleServerResponse(paymentResponse);
        }
      } catch (error) {
        this.hidePayLoading();
        console.error(error);
        toast.error(error.message ?? 'Oops, Something Went Wrong');
      }
    },
    async handleServerResponse(response) {
      try {
        if (response.error) {
          this.stripeValidationError = response.error.message;
          toast.error(response.error.message ?? 'Oops, Something Went Wrong');
        } else if (response.requires_action) {
          const { error: errorAction, paymentIntent } = await this.stripe.handleCardAction(
            response.payment_intent_client_secret
          );
          if (errorAction) {
            // this.stripeValidationError = response.error.message;
          } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            const serverResponse = await await StripeService.sendFormData({
              payment_intent_id: paymentIntent.id
            });
            this.handleServerResponse(await serverResponse);
          }
        } else {
          const { id, status, paymentMethod, customer } = response;
          console.log('Payment ID', id);
          console.log('Status', status);
          console.log('Customer', customer);
          if (status === 'Success' && id && paymentMethod) {
            this.isPayCompleted = true;
            this.payCompletedId = id;
            console.log('Body to SF', {
              id: id,
              paymentId: this.paymentId,
              paymentMethod: paymentMethod,
              customer: customer ?? null,
              amount: this.amount,
              email: this.email,
              name: this.fullName,
              firstName: this.firstName,
              lastName: this.lastName,
              phone: this.phone,
              address: this.shippingAddress
            });
            try {
              const response = await PaymentService.sendPaymentInfo({
                id: id,
                paymentId: this.paymentId,
                paymentMethod: paymentMethod,
                customer: customer ?? null,
                amount: this.amount,
                email: this.email,
                name: this.fullName,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                address: this.shippingAddress
              });
              if (response.status === 'Success') {
                console.log('Success');
              } else {
                toast.error(response.status ?? 'Oops, Something Went Wrong');
              }
            } catch (error) {
              toast.error(error?.message ?? 'Oops, Something Went Wrong');
              console.error(error);
            }
          }
        }
        this.hidePayLoading();
      } catch (error) {
        this.hidePayLoading();
        toast.error(error?.message ?? 'Oops, Something Went Wrong');
        console.error(error);
      }
    },
    showPayLoading() {
      this.isPayLoading = true;
    },
    hidePayLoading() {
      this.isPayLoading = false;
    },
    navigateToForm(id) {
      this.$router.push({ name: 'form', params: { id: id } });
    }
  }
};
</script>
