import Axios from '@/services/axios-instance/axios.service';
const API_URL =
  process.env.NODE_ENV !== 'development'
    ? `${window.location.origin}/api/payment/`
    : 'http://localhost:5000/api/payment/';

class PaymentService {
  async getPaymentInfo(id) {
    const { data } = await Axios.post(`${API_URL}getPaymentInfo`, id);
    return data;
  }
  async sendPaymentInfo(req) {
    const { data } = await Axios.post(`${API_URL}sendPaymentInfo`, req);
    return data;
  }
}

export default new PaymentService();
