<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-form class="p-5" @submit.prevent="submitForm">
    <b-row>
      <b-col cols="12" lg="6"><label>13 - Are you single?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="relationship.single"
            @change="singleChange()"
            :options="singleOptions"
            :disabled="isLoading"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!relationship.single">
      <b-col cols="12" lg="6"><label>14 - Exact date of your marriage or civil partnership?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="relationship.dateOfMarriedOrPartnership"
            type="date"
            @input="setTouched('dateOfMarriedOrPartnership')"
            :class="v$.relationship.dateOfMarriedOrPartnership.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div
            v-for="error of v$.relationship.dateOfMarriedOrPartnership.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6"
        ><label>15 - Did you change your name when you married or formed Civil Partnership?</label></b-col
      >
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="relationship.changeYouName"
            :options="singleOptions"
            :disabled="isLoading"
            @change="changeYouNameChange()"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="relationship.changeYouName">
      <b-col cols="12" lg="6"><label>16 - What was your full maiden / previous name?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="relationship.fullMaindenOrPreviousName"
            @input="setTouched('fullMaindenOrPreviousName')"
            :class="v$.relationship.fullMaindenOrPreviousName.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            placeholder="Enter"
          ></b-form-input>
          <div
            v-for="error of v$.relationship.fullMaindenOrPreviousName.$errors"
            class="invalid-feedback"
            :key="error.$uid"
          >
            {{ error.$message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="relationship.single">
      <b-col cols="12" lg="6"
        ><label
          >17 - Are you divorced, widowed, has your Civil Partnership been dissolved or are you a surviving
          partner?</label
        ></b-col
      >
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-select
            v-model="relationship.divorcedOrwidowed"
            :options="singleOptions"
            :disabled="isLoading"
            @change="divorcedOrwidowedChange()"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="relationship.single && relationship.divorcedOrwidowed">
      <b-col cols="12" lg="6"><label>18 - Exact date of this?</label></b-col>
      <b-col cols="12" lg="6">
        <div class="input-container">
          <b-form-input
            v-model="relationship.exactDate"
            type="date"
            @input="setTouched('exactDate')"
            :class="v$.relationship.exactDate.$error ? 'is-invalid' : ''"
            :disabled="isLoading"
            :max="todayDate"
            min="1900-01-01"
          ></b-form-input>
          <div v-for="error of v$.relationship.exactDate.$errors" class="invalid-feedback" :key="error.$uid">
            {{ error.$message }}
          </div>
          <b-form-text> What was the exact date of your divorce, partner’s death etc? </b-form-text>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, helpers, minLength, maxLength } from '@vuelidate/validators';
export default {
  name: 'RelationshipStep',
  props: {
    relationship: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: { type: Function, required: true }
  },
  data() {
    return {
      v$: useVuelidate(),
      todayDate: new Date().toISOString().slice(0, 10),
      singleOptions: [
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ]
    };
  },
  validations() {
    return {
      relationship: {
        single: {
          required: helpers.withMessage('Answer required', required)
        },
        dateOfMarriedOrPartnership: {
          requiredIf: helpers.withMessage(
            'Answer required',
            requiredIf(function () {
              return !this.relationship.single;
            })
          ),
          minValue: helpers.withMessage('Invalid Date.', (value) => {
            return new Date(value) > new Date('1900-01-01');
          }),
          maxValue: helpers.withMessage('Date not must be after today', (value) => {
            return new Date(value) <= new Date();
          })
        },
        fullMaindenOrPreviousName: {
          minLength: minLength(2),
          maxLength: maxLength(40),
          requiredIf: helpers.withMessage(
            'Answer required',
            requiredIf(function () {
              return this.relationship.changeYouName;
            })
          )
        },
        exactDate: {
          requiredIf: helpers.withMessage(
            'Answer required',
            requiredIf(function () {
              return this.relationship.single && this.relationship.divorcedOrwidowed;
            })
          ),
          minValue: helpers.withMessage('Invalid Date.', (value) => {
            return new Date(value) > new Date('1900-01-01');
          }),
          maxValue: helpers.withMessage('Date not must be after today', (value) => {
            return new Date(value) <= new Date();
          })
        }
      }
    };
  },
  methods: {
    singleChange() {
      if (this.relationship.single === true) {
        // eslint-disable-next-line vue/no-mutating-props
        this.relationship.dateOfMarriedOrPartnership = null;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.relationship.divorcedOrwidowed = false;
        // eslint-disable-next-line vue/no-mutating-props
        this.relationship.exactDate = null;
      }
    },
    divorcedOrwidowedChange() {
      if (this.relationship.divorcedOrwidowed === false) {
        // eslint-disable-next-line vue/no-mutating-props
        this.relationship.exactDate = null;
      }
    },
    changeYouNameChange() {
      if (this.relationship.changeYouName === false) {
        // eslint-disable-next-line vue/no-mutating-props
        this.relationship.fullMaindenOrPreviousName = null;
      }
    },
    setTouched(theModel) {
      if (theModel === 'dateOfMarriedOrPartnership' || theModel == 'all') {
        this.v$.relationship.dateOfMarriedOrPartnership.$touch();
      }
      if (theModel === 'fullMaindenOrPreviousName' || theModel == 'all') {
        this.v$.relationship.fullMaindenOrPreviousName.$touch();
      }
      if (theModel === 'exactDate' || theModel == 'all') {
        this.v$.relationship.exactDate.$touch();
      }
    },
    submitForm() {
      this.setTouched('all');
      if (!this.v$.$invalid) {
        this.onSubmit();
      }
    }
  }
};
</script>
